/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Title } from './title';


export interface PortalUserSearchFilter { 
    addressFilterInitialized?: boolean;
    accountCode?: string;
    excludeAccount?: boolean;
    roles?: Set<string>;
    createdOnOrAfter?: string;
    createdBefore?: string;
    id?: number;
    title?: Title;
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    city?: Set<string>;
    country?: Set<number>;
    userType?: PortalUserSearchFilter.UserTypeEnum;
    userId?: string;
    gender?: PortalUserSearchFilter.GenderEnum;
    zipCode?: Set<string>;
    emailVerified?: boolean;
    setupComplete?: boolean;
    displayTitle?: string;
    isInstructor?: boolean;
    tourRideStatus?: PortalUserSearchFilter.TourRideStatusEnum;
    isStudent?: boolean;
    preferredName?: string;
    profileImageUrl?: string;
    worksInAcademia?: boolean;
    ownsDentalOffice?: boolean;
    otherNames?: string;
    limit?: number;
    state?: Set<string>;
    displayName?: string;
    username?: string;
    status?: PortalUserSearchFilter.StatusEnum;
    offset?: number;
}
export namespace PortalUserSearchFilter {
    export type UserTypeEnum = 'DENTAL_OFFICE_ADMIN' | 'DENTAL_PROFESSIONAL' | 'DENTAL_RECEPTIONIST' | 'STUDENT' | 'PATIENT';
    export const UserTypeEnum = {
        DentalOfficeAdmin: 'DENTAL_OFFICE_ADMIN' as UserTypeEnum,
        DentalProfessional: 'DENTAL_PROFESSIONAL' as UserTypeEnum,
        DentalReceptionist: 'DENTAL_RECEPTIONIST' as UserTypeEnum,
        Student: 'STUDENT' as UserTypeEnum,
        Patient: 'PATIENT' as UserTypeEnum
    };
    export type GenderEnum = 'MALE' | 'FEMALE' | 'OTHER';
    export const GenderEnum = {
        Male: 'MALE' as GenderEnum,
        Female: 'FEMALE' as GenderEnum,
        Other: 'OTHER' as GenderEnum
    };
    export type TourRideStatusEnum = 'DASHBOARD' | 'AFFILIATE' | 'END' | 'CREATE_REFERRAL';
    export const TourRideStatusEnum = {
        Dashboard: 'DASHBOARD' as TourRideStatusEnum,
        Affiliate: 'AFFILIATE' as TourRideStatusEnum,
        End: 'END' as TourRideStatusEnum,
        CreateReferral: 'CREATE_REFERRAL' as TourRideStatusEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


