/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PromotionSearchFilter { 
    addressFilterInitialized?: boolean;
    headline?: string;
    promotionStatus?: PromotionSearchFilter.PromotionStatusEnum;
    dentalOffice?: Set<number>;
    promotionTypeCode?: Set<string>;
    dentalService?: Set<string>;
    promotionType?: Set<PromotionSearchFilter.PromotionTypeEnum>;
    modesOfPayment?: Set<PromotionSearchFilter.ModesOfPaymentEnum>;
    creationStartDate?: string;
    creationEndDate?: string;
    expiryStartDate?: string;
    expiryEndDate?: string;
    address?: string;
    withReviews?: number;
    city?: Set<string>;
    country?: Set<number>;
    zipCode?: Set<string>;
    limit?: number;
    state?: Set<string>;
    offset?: number;
}
export namespace PromotionSearchFilter {
    export type PromotionStatusEnum = 'ACTIVE' | 'EXPIRED';
    export const PromotionStatusEnum = {
        Active: 'ACTIVE' as PromotionStatusEnum,
        Expired: 'EXPIRED' as PromotionStatusEnum
    };
    export type PromotionTypeEnum = 'COUPON' | 'DEAL' | 'FREE_OFFER' | 'DENTAL_PLAN';
    export const PromotionTypeEnum = {
        Coupon: 'COUPON' as PromotionTypeEnum,
        Deal: 'DEAL' as PromotionTypeEnum,
        FreeOffer: 'FREE_OFFER' as PromotionTypeEnum,
        DentalPlan: 'DENTAL_PLAN' as PromotionTypeEnum
    };
    export type ModesOfPaymentEnum = 'SELF' | 'INSURANCE';
    export const ModesOfPaymentEnum = {
        Self: 'SELF' as ModesOfPaymentEnum,
        Insurance: 'INSURANCE' as ModesOfPaymentEnum
    };
}


