/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SubscriptionCyclePojo } from './subscription-cycle-pojo';
import { PaymentInvoicePojo } from './payment-invoice-pojo';
import { PortalUser } from './portal-user';


export interface DentalOfficeSubscriptionPojo { 
    paymentInvoice?: PaymentInvoicePojo;
    subscriptionCycle?: SubscriptionCyclePojo;
    packageItems?: Array<DentalOfficeSubscriptionPojo.PackageItemsEnum>;
    id?: number;
    dateCreated?: string;
    retryCount?: number;
    lastUpdated?: string;
    subscriptionStatus?: DentalOfficeSubscriptionPojo.SubscriptionStatusEnum;
    startDate?: string;
    endDate?: string;
    dateCancelled?: string;
    notified?: boolean;
    cancelledBy?: PortalUser;
    trial?: boolean;
    trialStartDate?: string;
    trialEndDate?: string;
    status?: DentalOfficeSubscriptionPojo.StatusEnum;
}
export namespace DentalOfficeSubscriptionPojo {
    export type PackageItemsEnum = 'OFFICE_GALLERY' | 'EMERGENCY_SOS' | 'SEND_REFERRAL' | 'RECEIVE_REFERRAL' | 'DREFER_ANALYTICS' | 'UP_TO_5_ADMIN_ACCESS' | 'UP_TO_3_ADMIN_ACCESS' | 'UNLIMITED_DENTAL_PROFESSIONALS' | 'UP_TO_5_DENTAL_PROFESSIONALS';
    export const PackageItemsEnum = {
        OfficeGallery: 'OFFICE_GALLERY' as PackageItemsEnum,
        EmergencySos: 'EMERGENCY_SOS' as PackageItemsEnum,
        SendReferral: 'SEND_REFERRAL' as PackageItemsEnum,
        ReceiveReferral: 'RECEIVE_REFERRAL' as PackageItemsEnum,
        DreferAnalytics: 'DREFER_ANALYTICS' as PackageItemsEnum,
        UpTo5AdminAccess: 'UP_TO_5_ADMIN_ACCESS' as PackageItemsEnum,
        UpTo3AdminAccess: 'UP_TO_3_ADMIN_ACCESS' as PackageItemsEnum,
        UnlimitedDentalProfessionals: 'UNLIMITED_DENTAL_PROFESSIONALS' as PackageItemsEnum,
        UpTo5DentalProfessionals: 'UP_TO_5_DENTAL_PROFESSIONALS' as PackageItemsEnum
    };
    export type SubscriptionStatusEnum = 'ACTIVE' | 'EXPIRED' | 'PENDING' | 'CANCELLED' | 'TRIAL';
    export const SubscriptionStatusEnum = {
        Active: 'ACTIVE' as SubscriptionStatusEnum,
        Expired: 'EXPIRED' as SubscriptionStatusEnum,
        Pending: 'PENDING' as SubscriptionStatusEnum,
        Cancelled: 'CANCELLED' as SubscriptionStatusEnum,
        Trial: 'TRIAL' as SubscriptionStatusEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


